// src/components/Contacts/Contacts.js
import React, { useState } from 'react';
import './contact.css';
import ConnectUs from '../ConnectUs/Connectus';

const Contacts = () => {
  
  return (
      <ConnectUs/>
  );
};

export default Contacts;
