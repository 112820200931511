import React from 'react';
import ReactDOM from 'react-dom/client';  // Import from react-dom/client for React 18
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

// Get the root container
const container = document.getElementById('root');

// Use ReactDOM.createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(container);
root.render(
  <Router>
    <App />
  </Router>
);
