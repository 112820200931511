import React, { useRef, useEffect } from 'react';
import './Gallery.css';

const Gallery = () => {
    const containerRefs = useRef([]);

    const addToRefs = (el) => {
        if (el && !containerRefs.current.includes(el)) {
            containerRefs.current.push(el);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('in-view');
                    }
                });
            },
            { threshold: 0.1 }
        );

        containerRefs.current.forEach((el) => {
            observer.observe(el);
        });

        return () => {
            containerRefs.current.forEach((el) => {
                observer.unobserve(el);
            });
        };
    }, []);

    const images = [
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729419702/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/empty-laboratory-with-nobody-it-prepared-genetic-test-using-professional-microscope_xqn9yp.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729418196/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/side-view-woman-experiment-sprout_hwb4zq.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729419696/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/man-woman-laboratory-robes-work-with-green-plants-greenhouse_yj1jrm.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729492448/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-21_at_11.33.57_ebf7e4f2_pjpo4p.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729492449/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-21_at_11.33.56_3483c1ea_adqpkg.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729492449/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-21_at_11.33.57_a233d621_tyx87c.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729492071/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-21_at_11.37.24_11477968_c9vljv.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729420319/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/852_wbh6gu.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729492071/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-21_at_11.37.25_0ffe6d22_im3sby.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729492070/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-21_at_11.37.25_631189e7_vrtmz8.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729944086/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-23_at_13.42.00_8b497cfb_jwux3k.jpg',
        'https://res.cloudinary.com/drevfgyks/image/upload/v1729944086/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-10-23_at_13.42.00_9c0033fb_spavnf.jpg'
    ];

    const videos = [
        'https://www.youtube.com/embed/dQw4w9WgXcQ',
        'https://www.youtube.com/embed/9bZkp7q19f0',
        'https://www.youtube.com/embed/3JZ_D3ELwOQ'
    ];

    const renderImages = (startIndex, endIndex) => (
        images.slice(startIndex, endIndex).map((src, index) => (
            <div 
                className="gallery-item" 
                key={index + startIndex} 
                ref={addToRefs}
                data-animation={index % 2 === 0 ? 'slide-right' : 'slide-left'}
            >
                <img src={src} alt={`Gallery ${index + 1 + startIndex}`} />
            </div>
        ))
    );

    return (
        <div className="gallery-wrapper">
            <div className="gallery-category">
                <h2>Lab Gallery</h2>
                <div className="gallery-container">
                    {renderImages(0, 3)}
                </div>
            </div>
            <div className="gallery-category">
                <h2>Industries</h2>
                <div className="gallery-container">
                    {renderImages(3, 6)}
                </div>
            </div>
            {/* <div className="gallery-category">
                <h2>Media Gallery</h2>
                <div className="gallery-container">
                    {renderImages(6, 9)}
                </div>
            </div> */}
            <div className="gallery-category">
                <h2>Field Gallery</h2>
                <div className="gallery-container">
                    {renderImages(6, 12)}
                </div>
            </div>
            {/* <div className="gallery-category">
                <h2>Videos</h2>
                <div className="video-container">
                    {videos.map((src, index) => (
                        <div className="video-item" key={index}>
                            <iframe 
                                width="100%" 
                                height="315" 
                                src={src} 
                                title={`Video ${index + 1}`} 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen
                            />
                        </div>
                    ))}
                </div>
            </div> */}
            <p className="EFE">Emerging Farmers Empowerment</p>
        </div>
    );
};

export default Gallery;
