import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './SocialMedia.css';

const SocialMedia = () => {
  return (
    <div className="social-media">
      <ul>
        <li><a href="https://www.facebook.com/profile.php?id=61567300678076" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
        {/* <li><a href="https://www.twitter.com" target="_blank"><i className="fab fa-twitter"></i></a></li> */}
        <li><a href="https://www.instagram.com/amaravathi.naturotech/" target="_blank"><i className="fab fa-instagram"></i></a></li>
        <li><a href="https://wa.me/9866672765" target="_blank"><i className="fab fa-whatsapp"></i></a></li>
        {/* <li><a href="https://www.youtube.com" target="_blank"><i className="fab fa-youtube"></i></a></li> */}
        <li><a href="https://www.linkedin.com/in/amaravathi-naturotech-937543333?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
      </ul>
    </div>
  );
};

export default SocialMedia;
