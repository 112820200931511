import React from 'react';
import { FaSeedling, FaWater, FaTree, FaTractor, FaLeaf, FaHandHoldingHeart, FaIndustry, FaChartBar, FaGlobe, FaClipboardCheck, FaUsers, FaHandshake } from 'react-icons/fa';
import './AgriTraining.css';

const AgriTraining = () => {
  const trainingModules = [
    { icon: <FaSeedling />, title: 'Introduction to Agriculture' },
    { icon: <FaWater />, title: 'Soil Science and Management' },
    { icon: <FaTree />, title: 'Crop Production and Management' },
    { icon: <FaWater />, title: 'Irrigation and Water Management' },
    { icon: <FaLeaf />, title: 'Fertilizers and Pest Management' },
    { icon: <FaHandHoldingHeart />, title: 'Sustainable and Organic Farming' },
    { icon: <FaTractor />, title: 'Farm Tools and Mechanization' },
    { icon: <FaTree />, title: 'Agroforestry and Climate-smart Agriculture' },
    { icon: <FaIndustry />, title: 'Post-Harvest Management and Value Addition' },
    { icon: <FaChartBar />, title: 'Agribusiness and Entrepreneurship' },
    { icon: <FaGlobe />, title: 'Field Visits and Hands-on Activities' },
    { icon: <FaLeaf />, title: 'Food Security and Nutrition' },
    { icon: <FaUsers />, title: 'Environmental Conservation and Agroecology' },
    { icon: <FaClipboardCheck />, title: 'Monitoring, Evaluation, and Certification' },
    { icon: <FaHandshake />, title: 'Policy and Advocacy' },
  ];

  return (
    <div className="agri-training-container">
      <h1 className='agri-Training-heading'>ANT Agricultural Training Programs</h1>
      <p className='agri-Training-para'>
        ANT provides Agricultural training programs in schools, colleges, and NGOs aimed to provide practical skills, technical knowledge, and awareness about sustainable farming practices. These training modules are generally tailored to different age groups and contexts (urban/rural) and can cover a wide range of topics.
      </p>

      <div className="training-images">
        <div className="image-container">
          <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729347363/international-day-education-dark-style-with-students-classroom_vsqiq1.jpg" alt="School Training" />
          <h3 className='cato-heading'>School Training</h3>
        </div>
        <div className="image-container">
          <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729347599/international-day-education-dark-style_vasxzx.jpg" alt="College Training" />
          <h3 className='cato-heading'>College Training</h3>
        </div>
        <div className="image-container">
          <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729350493/Farmers_Training_in_Indian_surroundings_with_greenery_in_the_style_of_Impressionist_paintings_dtio7w.jpg" alt="Farmers Training" />
          <h3 className='cato-heading'>Farmers Training</h3>
        </div>
        <div className="image-container">
          <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1729356561/people-taking-part-high-protocol-event_wlvhn5.jpg" alt="NGOs Training" />
          <h3 className='cato-heading'>NGOs Training</h3>
        </div>
      </div>

      <div className="training-modules">
        {trainingModules.map((module, index) => (
          <div className="module" key={index}>
            {module.icon}
            <h4>{module.title}</h4>
          </div>
        ))}
      </div>
      <p className="EFE">Emerging Farmers Empowerment</p>
    </div>
  );
};

export default AgriTraining;
