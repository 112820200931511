import React from 'react';
import './AgriClinicalServices.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faRecycle, faChartLine, faFileInvoiceDollar, faTree, faMapMarkedAlt, faCogs, faChartPie, faSitemap, faUsers, faLaptop } from '@fortawesome/free-solid-svg-icons';

const AgriClinicalServices = () => {
  return (
    <div className="agri-clinical-services">
      <section className="agri-section">
          {/* /* From Uiverse.io by Prince4fff */ }

          <div className="card">
            <div className="cad1">
            <div className="agri-heade">
              <div className='img-control'>
                <img 
                  src="https://res.cloudinary.com/drevfgyks/image/upload/v1725004448/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/2005.i518.010..automated_smart_garden_design_concept_banners_ifwf0u.jpg" 
                  alt="Farm Management Platform" 
                  className="header-image"
                />
              </div>
            </div>
            </div>
          </div>

          <div className="card">
            <a className="card1" href="#">
              <h1 className='title-clinical'>Advanced Farm Management Platform</h1>
              <p className="small">Amaravathi Naturo Tech Pvt Ltd is the most comprehensive and advanced agriculture ANT 
                platform which supports entire business processes for any sized plantation and farm. ANT simplifies 
                management of on-farm activities, harvest planning, quality control, packhouse operations, sales, export documentation, 
                and finance, which in turn helps the business in regulating market demand and supply of products and drive higher profit margins.</p>
                <p className="small">
                  Maintaining tree-wise inventory, observations, costs, valuation, and monitoring growth is made easy with our tree encoding and 
                  tree geo-tagging solutions. In addition, our farm analytics (BI) dashboards offer smarter data insights which enables management 
                  users to make the right decisions at the right time. Furthermore, ANT’s agriculture technology platform can also be customized or 
                  integrated with legacy financial software or smart hardware devices.
                </p>
              <div className="go-corner" href="#">
                <div className="go-arrow">
                  →
                </div>
              </div>
            </a>
          </div>
      </section>

      <section className="agri-hghlights">
        <div className="card5">
          <div className="content5">
            <svg
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://wwwrg/2000/svg"
            >
              <path
                d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"
              ></path>
            </svg>
            <h2 className='title-Highlights'>Highlights of ANT for  <br/> Plantations and Farm Management</h2>
            <p className="para5">
              <ul className='Highlights-ANT'>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faSeedling} /> Supports Multiple Crops, Locations, and Users
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faRecycle} /> Covers Entire Cycle of ‘Sapling to Sale’ and ‘Farm to Fork’
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faChartLine} /> Labour and Inputs Optimization
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faFileInvoiceDollar} /> Crop-wise Financial Reports
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faTree} /> Tree Encoding and Asset Valuation
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faMapMarkedAlt} /> Geo-mapping and Crop Scouting Solutions
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faCogs} /> Integration with Legacy Software Systems and Smart Hardware Devices
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faChartPie} /> Farm Analytics for Data Driven Decisions
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faSitemap} /> Organizational Hierarchy Mapping
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faUsers} /> Operator / Tapper Performance
                </li>
                <li className='icons-para'>
                  <FontAwesomeIcon icon={faLaptop} /> Accessed over Web, Mobile, and Tablet
                </li>
              </ul>
            </p>
          </div>
        </div>
      </section>


      {/* /* From Uiverse.io by Kemboi-Dun */}
      <div className='article-container'>          
          <article class="card10">
              <div class="temporary_text10">
                  <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725018118/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/business-agreement-shaking-hands-melon-plantation_brljhx.jpg" 
                  alt="Contract Farming Image" class="card_image10"/>
              </div>
              <div class="card_content10">
                  <h1 class="card_title10">Contract <br/>Farming</h1>
                  <ul class="card_description10">
                    <li class="card_description">Organic farming</li>
                    <li class="card_description">Commercial farming</li>
                    <li class="card_description">Corporate Level Farming</li>
                    <li class="card_description">Online Consultancy Services</li>
                    <li class="card_description">Custom Crop Management Plans</li>
                    <li class="card_description">Advanced Seed Selection</li>
                  </ul>
              </div>
          </article>
          <article class="card10">
              <div class="temporary_text10">
                  <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725001274/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/two-indian-business-man-suits-sitting-office-cafe-looking-laptop_qih0wn.jpg" 
                  alt="Contract Farming Image" class="card_image10"/>
              </div>
              <div class="card_content10">
                  <h2 class="card_title10">Consultation Services</h2>
                  <ul class="card_description10">
                  <li class="card_description">Soil Identification</li>
                  <li class="card_description">Soil preparations and planting</li>
                  <li class="card_description">Maintenance schedule</li>
                  <li class="card_description">Crop Production and Protection</li>
                  <li class="card_description">Harvest</li>
                  </ul>
              </div>
          </article>
        </div>
        <p className="EFE">Emerging Farmers Empowerment</p>
    </div>
  );
};

export default AgriClinicalServices;
