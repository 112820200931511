import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink } from 'react-router-dom';
import GetInTouch from '../GetInTouch/getintouch';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(prevIndex => (prevIndex === index ? null : index));
  };

  const closeDropdowns = () => {
    setActiveDropdown(null);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the sidebar
    closeDropdowns(); // Close the dropdowns
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.navbar')) {
        closeDropdowns();
        setIsOpen(false); // Close the sidebar when clicking outside
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <NavLink to="/" onClick={handleLinkClick} className='Logo-container'>
          <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1723460324/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Screenshot_2024-08-12_162428_lqkwsz.png" 
          alt="ANV Logo" 
          className='logo-icon'/>
          <h5 className='heading-quite'>Emerging Farmers Empowerment</h5>
        </NavLink>
      </div>
      <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <NavLink exact to="/" activeClassName="active" onClick={handleLinkClick}>Home</NavLink>
        <NavLink exact to="/about-us" activeClassName="active" onClick={handleLinkClick}>About us</NavLink>
        <NavLink to="/projects" activeClassName="active" onClick={handleLinkClick}>Projects</NavLink>
        <div className="navbar-dropdown">
          <a 
            href="#" 
            className="dropdown-link" 
            onClick={(e) => {
              e.preventDefault(); 
              toggleDropdown(0);
            }}
          >
            Services
          </a>
          <div className={`dropdown-content ${activeDropdown === 0 ? 'show' : ''}`}>
            <NavLink to="/our-services" activeClassName="active" onClick={handleLinkClick} className="sub-cat">OUR SERVICES</NavLink>
            <NavLink to="/arg-business" activeClassName="active" onClick={handleLinkClick}className="sub-cat">AGRI-BUSINNES</NavLink>
            <NavLink to="/arg-clinical" activeClassName="active" onClick={handleLinkClick} className="sub-cat">AGRI-CLINICAL</NavLink>
            <NavLink to="/our-process" activeClassName="active" onClick={handleLinkClick} className="sub-cat">OUR PROCESS</NavLink>
            <NavLink to="/agriTraining" activeClassName="active" onClick={handleLinkClick} className="sub-cat">AGRI TRAINING</NavLink>
          </div>
        </div>
        {/* Products Dropdown */}
        <div className="navbar-dropdown">
          <a 
            href="#" 
            className="dropdown-link" 
            onClick={(e) => {
              e.preventDefault(); 
              toggleDropdown(1);
            }}
          >
            Products
          </a>
          <div className={`dropdown-content ${activeDropdown === 1 ? 'show' : ''}`}>
            <NavLink to="/crops-products" activeClassName="active" onClick={handleLinkClick} className="sub-cat">CROPS</NavLink>
            <NavLink to="/ValueEdition" activeClassName="active" onClick={handleLinkClick} className="sub-cat">VALUE EDITION</NavLink>
          </div>
        </div>
        <NavLink to="/contact" activeClassName="active" onClick={handleLinkClick}>Contact</NavLink>
        <NavLink to="/career" activeClassName="active" onClick={handleLinkClick}>Career</NavLink>
        <NavLink to="/gallery" activeClassName="active" onClick={handleLinkClick}>Gallery</NavLink>
        {/* <button className="navbar-button" onClick={openPopup}>Get In Touch</button> */}
      </div>
      <div className="navbar-hamburger" onClick={toggleMenu}>
        <AiOutlineMenuUnfold size={30} />
      </div>
      
      {/* Popup Component */}
      {isPopupOpen && (
        <div className="popup active">        
          <button className='closepopup' onClick={closePopup}>✖</button>
          <GetInTouch />
        </div>
      )}
    </nav>
  );
};

export default Navbar;
