import { useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './curose.css'; // Import the custom CSS file
import { Link } from 'react-router-dom';

const Landingpage = ({ history }) => {

    useEffect(() => {
        const handleMouseMove = (e) => {
            const cursol = document.querySelector(".cursol");
            const cursol2 = document.querySelector(".cursol2");

            if (cursol && cursol2) {
                cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
                cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
            }
        };

        const handleMouseEnter = () => {
            const cursol = document.querySelector(".cursol");
            if (cursol) cursol.classList.add("hover-effect");
        };

        const handleMouseLeave = () => {
            const cursol = document.querySelector(".cursol");
            if (cursol) cursol.classList.remove("hover-effect");
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.addEventListener("mouseenter", handleMouseEnter);
            element.addEventListener("mouseleave", handleMouseLeave);
        });

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
                element.removeEventListener("mouseenter", handleMouseEnter);
                element.removeEventListener("mouseleave", handleMouseLeave);
            });
        };
    }, []);

    return (
        <>
            <div className="honey-products">
                <div className="header-section">
                    <Carousel>
                        {/* <Carousel.Item style={{height: '70vh', background: 'url(https://res.cloudinary.com/drevfgyks/image/upload/v1729969371/Screenshot_2024-10-27_003203_v4vcss.png' }} >
                            <div className="container-fluid">
                                <div className="ads-container">
                                    <div className="col-6 header-content">                                        
                                       
                                    </div>                                
                                </div>
                            </div>
                        </Carousel.Item> */}
                        <Carousel.Item className="carousel-item-custom">
                            <div className="container-fluid">
                                <div className="ads-container">
                                <div className="col-6 header-content">                                        
                                    {/* <Link to="/honeyproductstwo"><button className="btn btn-primary">Buy Now</button></Link> */}
                                </div>                                
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{height: '60vh', background: 'url(https://res.cloudinary.com/drevfgyks/image/upload/v1728211578/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/top-view-herbal-therapy-products_zwha4f.jpg) no-repeat center center/cover' }} >
                            <div className="container-fluid">
                                <div className="ads-container">
                                    <div className="col-6 header-content">
                                        <h1 className='ads-headingtwo'>Sustainable practices ensure our quality, protecting the planet while enhancing productivity.</h1>                                        
                                        {/* <Link to="/honeyproductstwo"><button className="btn btn-primary">Buy Now</button></Link> */}
                                    </div>                                
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '60vh', background: 'url(https://res.cloudinary.com/drevfgyks/image/upload/v1728212020/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/flat-lay-asian-food-spices-mix-chopsticks_wovqks.jpg) no-repeat center center/cover' }}>
                            <div className="container-fluid">
                                <div className="ads-container">
                                    <div className="col-6 header-content">
                                        <h1 className='ads-headingtwo'>Exceeding expectations through expert service and high-quality materials for every project.</h1>                                        
                                        {/* <Link to="/CoffeeProducts2"><button className="btn btn-primary">Buy Now</button></Link> */}
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '60vh', background: 'url(https://res.cloudinary.com/drevfgyks/image/upload/v1722939558/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/beautiful-japanese-forest-scene_rgifox.jpg) no-repeat center center/cover' }}>
                            <div className="container-fluid">
                                <div className="ads-container">
                                    <div className="col-6 header-content">
                                        <h1 className='ads-headingtwo'>Blending innovation with quality, delivering the best in agriculture and landscaping services.</h1>                                       
                                        {/* <Link to="/all-products"><button className="btn btn-primary">Click All</button></Link> */}
                                    </div>                                   
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '60vh', background: 'url(https://res.cloudinary.com/drevfgyks/image/upload/v1728212675/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Wood_plants_rh6lnf.jpg) no-repeat center center/cover' }}>
                            <div className="container-fluid">
                                <div className="ads-container">
                                    <div className="col-6 header-content">
                                        <h1 className='ads-headingtwo'>Promoting eco-friendly methods that sustain both our quality and the environment, driving growth and efficiency.</h1>                                       
                                        {/* <Link to="/all-products"><button className="btn btn-primary">Click All</button></Link> */}
                                    </div>                                   
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '60vh', background: 'url(https://res.cloudinary.com/drevfgyks/image/upload/v1728213001/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/close-up-adult-cooking_l1rsne.jpg) no-repeat center center/cover' }}>
                            <div className="container-fluid">
                                <div className="ads-container">
                                    <div className="col-6 header-content">
                                        <h1 className='ads-headingtwo'>Delivering unmatched results through skilled expertise and premium materials, ensuring excellence in every project.</h1>                                       
                                        {/* <Link to="/all-products"><button className="btn btn-primary">Click All</button></Link> */}
                                    </div>                                   
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '60vh', background: 'url(https://res.cloudinary.com/drevfgyks/image/upload/v1728213007/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/modern-kitchen-composition-with-healthy-ingredients_rnxjc5.jpg) no-repeat center center/cover' }}>
                            <div className="container-fluid">
                                <div className="ads-container">
                                    <div className="col-6 header-content">
                                        <h1 className='ads-headingtwo'>Combining advanced techniques with superior quality, we offer top-tier agricultural and landscaping solutions.</h1>                                       
                                        <Link to="/crops-products"><button className="btn btn-primary">Click All</button></Link>
                                    </div>                                   
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </>
    );
};

export default Landingpage;
