import './Career.css';
import { FaHandsHelping, FaClock, FaBalanceScale, FaChartLine, FaTrophy, FaEnvelope, FaPhone } from 'react-icons/fa';

const Career = () => {
    return (
        <>
            <div className="career-container">
                <div>
                    <img 
                        src="https://res.cloudinary.com/drevfgyks/image/upload/v1725183812/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Green_and_Neutral_Organic_Shapes_Women_Leadership_Empowerment_Presentation_bvkrf1.jpg"
                        className="career-banner" 
                        alt="Career banner"
                    />
                </div>
                <div className='career-details-container'>
                    <div className="career-benefits">
                        <h2>Benefits of Being a Part of ANT</h2>
                        <ul>
                            <li><FaHandsHelping className="career-icon" /> Enhancement of skills with continuous support and motivation</li>
                            <li><FaClock className="career-icon" /> Adaptable working hours tailored to your needs</li>
                            <li><FaBalanceScale className="career-icon" /> Comprehensive statutory benefits and flexible benefit plans</li>
                            <li><FaChartLine className="career-icon" /> Professional growth guided by a balanced scorecard approach</li>
                            <li><FaTrophy className="career-icon" /> Incentives and bonuses based on performance</li>
                        </ul>
                    </div>
                    <div className="career-benefits">
                        <h2>Send Your Resume</h2>
                        <ul>
                            <li><img src='https://res.cloudinary.com/drevfgyks/image/upload/v1725192789/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/illustration-folder-with-document_amptfi.png'
                            className='resume-file'/></li>
                            <li className='listforemail'><FaEnvelope className="career-icon" />Email: amaravathi.naturotech1@gmail.com</li>
                            <li><FaPhone className="career-icon" />Phone: 6264664390</li>
                        </ul>
                    </div>
                </div>
                <div className="career-video">
                    {/* <h2>Watch Our Journey</h2> */}
                    {/* <iframe 
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/JOYwDUTOahU?si=3KsqPsLyDXtqCgkJ" 
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen
                    ></iframe>
                    <iframe 
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/R_InO9bxuwU?si=_jotJ2luqGUu1V85" 
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen
                    ></iframe> */}
                </div>
                <p className="EFE">Emerging Farmers Empowerment</p>
            </div>
        </>
    );
}

export default Career;
