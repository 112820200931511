// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/crops-products">Products</a></li>
            {/* <li><a href="/services">Agri-Clinical Services</a></li>
            <li><a href="/business">Agri-Business Services</a></li> */}
            {/* <li><a href="/projects">Projects</a></li> */}
            <li><a href="/career">Career</a></li>
            <li><a href="/gallery">Gallery</a></li>
            {/* <li><a href="/contact">Get in Touch</a></li> */}
          </ul>
        </div>
        <div className="footer-section">
          <h3>Social Media Links</h3>
          <ul className="social-media1">
            <li><a href="https://www.facebook.com/profile.php?id=61567300678076" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><a href="https://www.instagram.com/amaravathi.naturotech/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li><a href="https://www.linkedin.com/in/amaravathi-naturotech-937543333?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://wa.me/9866672765?text=Welcome%20to%20ANT" target="_blank" rel="noopener noreferrer">WhatsApp</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <div className="text-footer-section">
          <h3>Company Address</h3>
          <p>Amaravathi Naturo Tech Pvt Ltd</p>
          <p>Kantepudi Village, D.No.2-44</p>
          <p>Sattenapalli Mandal-Guntur-AP-522403</p>
          <p>Email: amaravathi.naturotech1@gmail.com</p>
          <p>Phone: +91 9866672765</p>
          <p>Phone: +91 6264664390</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
