import React, { useEffect } from 'react';
import './aboutus.css';

const AboutUs = () => {
  
  useEffect(() => {
    const counters = document.querySelectorAll('.counter');
    const speed = 200; // The lower the speed, the faster the counting

    counters.forEach(counter => {
      const updateCount = () => {
        const target = +counter.getAttribute('data-target');
        const count = +counter.innerText;
        const increment = target / speed;

        if (count < target) {
          counter.innerText = Math.ceil(count + increment);
          setTimeout(updateCount, 1);
        } else {
          counter.innerText = target;
        }
      };

      updateCount();
    });

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        }
      });
    });

    const elements = document.querySelectorAll('[data-animation]');
    elements.forEach(el => observer.observe(el));

  }, []);

  return (
    <div className="about-us about-us-content">
      <header className="about-us-header">
        <img 
          src='https://res.cloudinary.com/drevfgyks/image/upload/v1724859736/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/creative-lush-green-leaves-pattern-nature-texture-background_uft57t.jpg' 
          alt='about-banner' 
          className='about-banner' 
        />        
      </header>
      <section className="about-us-content">
        
        <div className="stats-container">
          <div className='three-count' data-animation="slide-left">
            <div className="stat">
              <h2 className="counter" data-target="15">0</h2>
              <p>Years of experience</p>
            </div>
            <div className="stat">
              <h2 className="counter" data-target="347">0</h2>
              <p>Successful Projects</p>
            </div>
          </div>

          <div className='three-count' data-animation="slide-right">
            <div className="stat">
              <h2 className="counter" data-target="49">0</h2>
              <p>No. of FPO's</p>
            </div>
            <div className="stat">
              <h2 className="counter" data-target="97">0</h2>
              <p>B2B Projects</p>
            </div>
          </div>  

          <div className='three-count' data-animation="slide-left">
            <div className="stat">
              <h2 className="counter" data-target="307">0</h2>
              <p>B2C Projects</p>
            </div>
            <div className="stat">
              <h2 className="counter" data-target="570">0</h2>
              <p>Clients</p>
            </div>
          </div> 
        </div>
        
        <div className="map-and-stats">
          <div className="india-map" data-animation="slide-left">
            <img 
              src='https://res.cloudinary.com/drevfgyks/image/upload/v1729409372/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Screenshot_2024-10-20_125800_ueo152.png' 
              alt='India Map' 
              className='map-image' 
            />
          </div>

          <div className="stats-section" data-animation="slide-right">
            <div className="stat-card">
              <div className="stat-icon">🌍</div>
              <h2 className="counter" data-target="26">0</h2>
              <p>States</p>
            </div>
            <div className="stat-card">
              <div className="stat-icon">👥</div>
              <h2 className="counter" data-target="278">0</h2>
              <p>Dealers</p>
            </div>
            <div className="stat-card">
              <div className="stat-icon">📍</div>
              <h2 className="counter" data-target="409">0</h2>
              <p>Villages</p>
            </div>
            <div className="stat-card">
              <div className="stat-icon">🎓</div>
              <h2 className="counter" data-target="47">0</h2>
              <p>Management Expertise</p>
            </div>
          </div>
          
        </div>

        <div className="company-info" data-animation="slide-left">
          <h2>About Amaravathi Naturo Tech Pvt Ltd</h2>
          <p className='heading-para'>Amaravathi Naturo Tech Pvt Ltd is a company specializing in agricultural farming, wood plantation, fruit crops, and landscaping. The company focuses on sustainable agricultural practices and innovative techniques to enhance productivity and profitability in farming and plantations.</p>
          
          <h3>Key Areas of Focus:</h3>
          <ul>
            <li><strong>Agricultural Farming:</strong> Sustainable farming methods, modern technologies, organic farming principles for healthy, chemical-free produce.</li>
            <li><strong>Wood Plantation:</strong> High-yield, fast-growing tree species, reforestation, afforestation projects, and scientific approaches for superior wood quality.</li>
            <li><strong>Fruit Crops:</strong> Advanced horticultural techniques, high-quality produce, integrated pest management, and organic practices.</li>
            <li><strong>Landscaping:</strong> Aesthetic and functional landscape designs using native plants and sustainable practices.</li>
          </ul>
          
          <h3>Vision and Mission:</h3>
          <ul>
          <li><strong>Vision:</strong> To be a leading provider of sustainable agricultural solutions, promoting eco-friendly practices and contributing to food security and environmental conservation.</li>
          <li><strong>Mission:</strong> Innovate and implement advanced agricultural techniques, enhance productivity, and provide high-quality produce while maintaining ecological balance and sustainability.</li>
          </ul>
          <h3>Core Values:</h3>
          <ul>
            <li><strong>Sustainability:</strong> Commitment to sustainable practices in all aspects of farming and plantation.</li>
            <li><strong>Innovation:</strong> Continuous innovation in farming techniques and technologies.</li>
            <li><strong>Quality:</strong> Focus on producing high-quality crops and wood.</li>
            <li><strong>Environmental Stewardship:</strong> Dedication to protecting and preserving the environment through responsible practices.</li>
          </ul>
          <h3>Services Offered:</h3>
          <ul>
            <li>Agricultural consultancy and support.</li>
            <li>Supply of high-quality seeds and planting materials.</li>
            <li>Plantation management and advisory services.</li>
            <li>Landscaping design and implementation.</li>
          </ul>
        </div>
      </section>
      <p className="EFE">Emerging Farmers Empowerment</p>
    </div>
  );
};

export default AboutUs;
