import React, { useState, useRef, useEffect } from 'react';
import './BenefitsOffered.css';
import { Link } from 'react-router-dom';

const BenefitsOffered = ({ benefits }) => {
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setAnimationTriggered(true);
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.1 });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="main-benefits-offered" ref={sectionRef}>
      <div data-animation={animationTriggered ? "slide-right" : ""}>
        <h1 className="benifits-title">BENEFITS<br/>OFFERED</h1>  
      </div>
      <div className="university-selection">
            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1728151146/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/coronavirus-financial-recovery_ip0naj.png' alt="University Icon" className="university-icon" />
            <h2 className="title-OFFERED">Increased Productivity and Efficiency</h2>
            <p className="description">
            Advanced Machinery and Technology: Use of modern farming equipment like GPS-guided tractors, 
            automated irrigation systems, and drone technology to ensure maximum yield with minimal manual intervention...
            </p>
            <Link to="/benefitsdetails"><button className="contact-btn2">
              Learn More <span className="arrow2">➔</span>
            </button></Link>
        </div>
      </div>
  );
};

export default BenefitsOffered;
