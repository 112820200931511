import React from 'react';
import './ValueEdition.css';

const herbsData = [
  { 
    name: 'Ashwagandha', 
    benefit: 'Reduces stress, anxiety, and boosts energy', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421637/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Ashawagdan_hewp98.jpg' 
  },
  { 
    name: 'Turmeric', 
    benefit: 'Anti-inflammatory and antioxidant properties', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421636/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/turmoric_l6meh5.jpg' 
  },
  { 
    name: 'Neem', 
    benefit: 'Supports skin health, immune system, and detoxification', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421860/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/neem_powder_ia9hpw.jpg' 
  },
  { 
    name: 'Moringa', 
    benefit: 'Rich in vitamins, minerals, and antioxidants', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421860/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/moring_powder_afliqq.jpg' 
  },
  { 
    name: 'Tulsi (Holy Basil)', 
    benefit: 'Supports respiratory health and reduces stress', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421903/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/tulasi_izcgju.jpg' 
  },
  { 
    name: 'Shatavari', 
    benefit: 'Supports women’s health and hormonal balance', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421903/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/shatavari_jy4ika.jpg' 
  },
  { 
    name: 'Ginger', 
    benefit: 'Helps with digestion, nausea, and inflammation', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421941/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Zingar_qdfpky.jpg' 
  },
  { 
    name: 'Amla (Indian Gooseberry)', 
    benefit: 'Rich in Vitamin C, supports immunity and skin health', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421942/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Amla_lqfozd.jpg' 
  },
  { 
    name: 'Brahmi', 
    benefit: 'Enhances cognitive function and reduces stress', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421986/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Brahmi_qpz4xv.jpg' 
  },
  { 
    name: 'Triphala', 
    benefit: 'Aids in digestive health and detoxification', 
    image: 'https://res.cloudinary.com/drevfgyks/image/upload/v1729421984/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/thripala_etuxs7.jpg' 
  },
];

const ValueEdition = () => {
  return (
    <div className="value-edition-container">
      <h2>Value Edition</h2>
      <div className="herbs-list">
        {herbsData.map((herb, index) => (
          <div key={index} className="herb-card">
            <div className="herb-image">
              <img src={herb.image} alt={herb.name} />
            </div>
            <h3>{herb.name}</h3>
            <p>{herb.benefit}</p>
          </div>
        ))}
        <h4>Note: we deal with all raw medicinal herbs ,wood  products, and powders, as well as many more value editions</h4>
      </div>
      <p className="EFE">Emerging Farmers Empowerment</p>
    </div>
  );
};

export default ValueEdition;
