import React, { useState, useEffect, useRef } from 'react';
import './SuccessJourney.css';

const images = [
  'https://res.cloudinary.com/drevfgyks/image/upload/v1723254184/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-08-05_at_16.37.20_26cb257c_catjwu.jpg',
  'https://res.cloudinary.com/drevfgyks/image/upload/v1723254184/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-08-05_at_16.37.54_74e22d58_wwkyiv.jpg',
  'https://res.cloudinary.com/drevfgyks/image/upload/v1723254183/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/WhatsApp_Image_2024-08-05_at_16.35.13_bf88f373_i9ouma.jpg',
];

const SuccessJourney = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setAnimationTriggered(true);
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.1 });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  // Function to handle the previous button click
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Function to handle the next button click
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="success-journey" ref={sectionRef}>
      <div className="carousel2">
        <div className="carousel-inner" data-animation={animationTriggered ? "slide-left" : ""}>
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
            >
              <img src={image} alt={`carousel-item-${index}`} className="carousel-image" />
            </div>
          ))}
        </div>
        <button className="carousel-control-prev2" onClick={handlePrevClick}>
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next2" onClick={handleNextClick}>
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="content" data-animation={animationTriggered ? "slide-left" : ""}>
        <h2 className="SuccessTitle">Our Success Journey</h2>
        <p className="description">
        At Amaravathi Naturo Tech Pvt Ltd our journey began with a simple vision: to nurture the land, feed communities, and foster sustainable farming practices 
        for generations to come. With roots deeply planted in the heart of the agriculture industry, we’ve grown from a small family-owned 
        farm to a leading agricultural enterprise, driven by innovation, integrity, and a commitment to the earth.
        </p>
        <p className='para5'><span>From Humble Beginnings to Global Impact, </span>founded in [2017], our company was built on the principles of hard work, dedication, and a passion for farming. What started as a 
          modest farm with a handful of crops has blossomed into a multifaceted agricultural business that spans across alover india. Over the years, we've embraced new technologies, sustainable practices, and strategic partnerships 
          that have allowed us to expand our reach while staying true to our core values.</p>
          <p className='para5'><span >Our Mission: Feeding the future</span> we believe agriculture is the backbone of a healthy society. 
          That’s why our mission is twofold: to provide the highest quality products to our customers and to safeguard the environment through 
          sustainable farming. From seed to harvest, every step of our process is designed with care, ensuring that we meet the nutritional needs 
          of today while protecting the resources of tomorrow.</p>
      </div>
    </div>
  );
};

export default SuccessJourney;
