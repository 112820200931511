import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './BusinessProfile.css';

const BusinessProfile = () => {
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setAnimationTriggered(true);
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.1 });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
    <div className="business-profile" ref={sectionRef}>
            <div className='service-item1'>                      
              <div className="service-item">
                  <div data-animation={animationTriggered ? "slide-right" : ""}>                       
                  <img
                  src="https://res.cloudinary.com/drevfgyks/image/upload/v1723376454/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/5178587_mg8gxr.jpg"
                  alt="AGRI-BUSINESS SERVICES"
                  className="agri-business-icon"
                  />
                  {/* <h2 className="heading">AGRI-BUSINESS SERVICES</h2> */}
                  </div>
                  <div data-animation={animationTriggered ? "slide-left" : ""}>
                  <img
                  src="https://res.cloudinary.com/drevfgyks/image/upload/v1728216957/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Screenshot_2024-08-31_171410_baizoc.png"
                  alt="AGRI-CLINICAL SERVICES"
                  className="agri-clinical-icon"
                  />
                  {/* <h2 className="heading">AGRI-CLINICAL SERVICES</h2> */}
                  </div>
              </div>
              <div data-animation={animationTriggered ? "slide-left" : ""}>
              <h1 className='business-titile'>BUSINESS<br/>PROFILE</h1>
              <Link to="/arg-business"><button className="contact-btn2">
                  Learn More <span className="arrow2">➔</span>
              </button></Link>
            </div>
      </div>
    </div>
  );
};

export default BusinessProfile;
