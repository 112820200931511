import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faChartLine, 
    faCoins, 
    faLeaf, 
    faTruck, 
    faCrop, 
    faMicroscope, 
    faCheckCircle, 
    faLifeRing, 
    faShieldAlt, 
    faUsers 
} from '@fortawesome/free-solid-svg-icons';
import './BenefitsDetails.css';

// Benefits data structure
const benefitsData = [
    {
        title: 'Increased Productivity and Efficiency',
        icon: faChartLine,
        details: [
            'Advanced Machinery and Technology: Use of modern farming equipment like GPS-guided tractors, automated irrigation systems, and drone technology to ensure maximum yield with minimal manual intervention.',
            'Precision Farming: Implementing data-driven farming techniques to optimize planting, watering, and fertilization schedules, resulting in higher productivity.',
            'Expert Agronomy Services: Access to expert agronomists who provide tailored solutions to improve crop quality and soil health.',
        ],
    },
    {
        title: 'Cost-Effective Solutions',
        icon: faCoins,
        details: [
            'Bulk Production: Commercial farming enables economies of scale, reducing overall costs per unit of production.',
            'Customized Farming Packages: Offering flexible service packages based on the specific needs of clients, from full-service farming to tailored support, helping to manage budgets effectively.',
            'Reduced Operational Costs: Through optimized farming techniques and resource management, reduce labor, energy, and water usage costs.',
        ],
    },
    {
        title: 'Sustainability and Eco-Friendly Practices',
        icon: faLeaf,
        details: [
            'Sustainable Farming Practices: Using organic farming methods, crop rotation, and integrated pest management to ensure environmentally friendly operations.',
            'Water Conservation: Implementing efficient irrigation systems, such as drip irrigation, to conserve water and reduce waste.',
            'Soil Health Management: Regular soil testing and amendment recommendations to maintain soil fertility without over-reliance on chemical fertilizers.',
        ],
    },
    {
        title: 'Reliable Supply Chain and Distribution',
        icon: faTruck,
        details: [
            'Consistent Output: Guaranteeing reliable, large-scale production for continuous supply to markets, food processors, and retailers.',
            'Efficient Logistics: Offering transportation and storage solutions to ensure fresh, timely delivery of produce to clients.',
            'Contract Farming: Secure, long-term agreements with buyers, guaranteeing a consistent supply of crops at predetermined prices.',
        ],
    },
    {
        title: 'Customized Solutions for Various Sectors',
        icon: faCrop,
        details: [
            'Crop Diversification: Offering a range of crops and farming services to meet the needs of various sectors such as food, biofuels, or livestock feed production.',
            'Farm-to-Market Programs: Direct marketing and supply chain solutions for farmers to sell directly to consumers, retailers, or food processors.',
            'Farm Management Services: Providing complete farm management, including land preparation, planting, crop management, harvesting, and post-harvest processes.',
        ],
    },
    {
        title: 'Cutting-Edge Research and Innovation',
        icon: faMicroscope,
        details: [
            'Research and Development: Continuous investment in R&D to improve crop varieties, pest control methods, and climate resilience techniques.',
            'Adoption of Smart Farming Technologies: Utilization of AI, IoT, and data analytics to predict weather patterns, track crop growth, and monitor resource usage in real-time.',
        ],
    },
    {
        title: 'Compliance with Industry Standards',
        icon: faCheckCircle,
        details: [
            'Certified and Regulated: Compliance with local and international agricultural standards, ensuring safety, quality, and environmental protection.',
            'Traceability and Transparency: Providing full traceability of crops from farm to table, ensuring high-quality standards throughout the supply chain.',
        ],
    },
    {
        title: 'Expert Support and Consultation',
        icon: faLifeRing,
        details: [
            '24/7 Support: Offering ongoing technical support and consultation to ensure client success, including troubleshooting, crop health monitoring, and yield maximization.',
            'Training and Education: Providing educational programs and workshops to help farmers and partners adopt the latest technologies and sustainable practices.',
        ],
    },
    {
        title: 'Risk Management and Crop Insurance',
        icon: faShieldAlt,
        details: [
            'Mitigation of Weather Risks: Access to climate-resilient farming strategies, such as drought-resistant seeds and pest management to reduce crop loss.',
            'Crop Insurance: Offering crop insurance options to safeguard against unforeseen disasters like droughts, floods, or pest infestations.',
        ],
    },
    {
        title: 'Boost to Local Economies',
        icon: faUsers,
        details: [
            'Job Creation: Supporting local communities by providing employment opportunities in farming operations, processing, and logistics.',
            'Community Engagement: Involvement in local agricultural development projects and sustainable farming initiatives to uplift rural economies.',
        ],
    },
];

// BenefitsDetails component
const BenefitsDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="benefits-container-Details">
            <h1>Benefits Offered</h1>
            <div className="benefits-list-Details">
                {benefitsData.map((benefit, index) => (
                    <div key={index} className="benefit-item-Details">
                        <FontAwesomeIcon icon={benefit.icon} className="benefit-icon-Details" />
                        <h3>{benefit.title}</h3>
                        <ul>
                            {benefit.details.map((detail, i) => (
                                <li key={i}>{detail}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BenefitsDetails;
