import React from 'react';
import './ourprocess.css';

const OurProcess = () => {
  const processSteps = [
    { title: 'Consultation', description: 'Providing expert advice tailored to your farming needs.' },
    { title: 'Soil Identification', description: 'Analyzing soil to determine the best crops for your land.' },
    { title: 'Soil Preparations and Planting', description: 'Preparing the soil and planting crops for optimal growth.' },
    { title: 'Maintenance Schedule', description: 'Creating a maintenance plan to ensure healthy crops.' },
    { title: 'Crop Production and Protection', description: 'Overseeing crop production and implementing protective measures.' },
    { title: 'Harvest', description: 'Harvesting crops at their peak for maximum yield.' },
  ];

  return (
    <div className="our-process">
      <div className="process-image">
        <img 
          src="https://res.cloudinary.com/drevfgyks/image/upload/v1725031277/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/One_Million_Trees_Day_gxtyef.png"
          alt="Process Roadmap" 
          className="nav-image" 
        />
      </div>
      <div className="process-roadmap">
        {processSteps.map((step, index) => (
          <div key={index} className="process-step">
            <div className="step-indicator">
              <div className="step-number">{index + 1}</div>
              {index < processSteps.length - 1 && <div className="step-line"></div>}
            </div>
            <div className="step-content">
              <div className="step-title">{step.title}</div>
              <div className="step-description">{step.description}</div>
            </div>
          </div>
        ))}
      </div>
      <p className="EFE">Emerging Farmers Empowerment</p>
    </div>
  );
};

export default OurProcess;
