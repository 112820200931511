import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FaSeedling, FaOilCan, FaAppleAlt, FaCarrot, FaPepperHot, FaPills,
  FaCoffee, FaLeaf, FaGlobeAmericas, FaDog, FaTree, FaMountain, FaRoad
} from 'react-icons/fa';
import './HomeProducts.css';

const icons = [
  { id: 1, icon: <FaSeedling />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 2, icon: <FaOilCan />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 3, icon: <FaAppleAlt />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 5, icon: <FaCarrot />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 6, icon: <FaPills />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 7, icon: <FaCoffee />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 8, icon: <FaLeaf />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 9, icon: <FaGlobeAmericas />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 10, icon: <FaPepperHot />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 11, icon: <FaDog />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 12, icon: <FaTree />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 13, icon: <FaMountain />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
  { id: 14, icon: <FaRoad />, label: 'All Agriculture & Horticulture Crops', description: 'Agriculture is the practice of cultivating soil, growing crops (all field crops), and raising animals for food, fiber, and other products used to sustain and enhance human life. Horticulture is a branch of agriculture that focuses specifically on the cultivation of plants, wood trees, including fruits, vegetables, flowers, and ornamental plants, for both aesthetic and functional purposes.' },
];

const carouselImages = [
  'https://res.cloudinary.com/drevfgyks/image/upload/v1728214463/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/environmental-conservation-plant-sustainability_1_eajont.jpg',
];

const HomeProducts = () => {
  const [selected, setSelected] = useState(1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        }
      });
    });

    const elements = document.querySelectorAll('[data-animation]');
    elements.forEach(el => observer.observe(el));

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleClick = (id) => {
    setSelected(selected === id ? null : id);
    setCurrentImageIndex(0);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + carouselImages.length) % carouselImages.length);
  };

  const selectedIcon = icons.find(icon => icon.id === selected);

  return (
    <div className="home-products">
      <p className='Title-Products'>PRODUCTS</p>
      <div className='product-details'>
        <div className="icons-row" data-animation="slide-left">
          {icons.map((icon) => (
            <div key={icon.id} className="icon-container" onClick={() => handleClick(icon.id)}>
              <div className={`icon ${selected === icon.id ? 'active' : ''}`}>
                {icon.icon}
              </div>
            </div>
          ))}
        </div>
        {selected && (
          <div className="details-container" data-animation="slide-right">
            <div className="carousel-container">
              <button className="carousel-button prev" onClick={handlePrev}>❮</button>
              <img src={carouselImages[currentImageIndex]} alt="Carousel" className="carousel-image" />
              <button className="carousel-button next" onClick={handleNext}>❯</button>
            </div>
            <div className="info-container">
              <h1>{selectedIcon.label}</h1>
              <p className='Description'>{selectedIcon.description}</p>
              <Link to="/crops-products"><button>Learn More</button></Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeProducts;
