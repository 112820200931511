import React, { useState } from 'react';
import './OurProcessAndServices.css';

const OurProcessAndServices = () => {
  const [showContent, setShowContent] = useState({
    corporateFarming: { showProcesses: false, showServices: false },
    naturalFarming: { showProcesses: false, showServices: false },
    farmerDrivenFarming: { showProcesses: false, showServices: false },
    precisionFarming: { showProcesses: false, showServices: false },
  });

  const toggleContent = (section, type) => {
    setShowContent((prevState) => {
      const newState = { ...prevState };
      const isCurrentlyOpen = newState[section][type];

      // Close all sections
      for (let key in newState) {
        newState[key][type] = false;
      }

      // Toggle the clicked section
      newState[section][type] = !isCurrentlyOpen;

      return newState;
    });
  };

  const content = {
    precisionFarming: {
      title: 'Commercial Farming',
      overview:
        'Commercial farming is a large-scale agricultural production aimed primarily at selling products in the market for profit. It focuses on maximizing yields and efficiency through modern technologies, machinery, and intensive farming methods.',
      processes: [
        'Land Management: Commercial farms often operate on extensive lands to grow crops or raise livestock in high volumes.',
        'Irrigation & Water Management: Efficient irrigation techniques, such as drip and sprinkler systems, ensure the optimal use of water resources.',
        'Chemical Inputs: The use of synthetic fertilizers, pesticides, and herbicides helps boost productivity and manage pests and weeds',
        'High-Yield Varieties (HYVs): Farmers use genetically modified or hybrid seeds to increase crop yields.',
      ],
      services: [
        'Supply Chain Integration: Commercial farms are linked to advanced supply chains that include processing, packaging, and distribution to national or international markets.',
        'Agronomic Consulting: Experts provide ongoing advice on crop selection, pest control, and yield improvement.',
        'Financial Services: Access to agricultural loans and insurance plans tailored to large-scale operations.',
        'Market Access: Commercial farmers often work with large retailers, wholesalers, or direct export markets.',
      ],
    },
    corporateFarming: {
      title: 'Corporate Farming',
      overview:
        'Corporate farming refers to agriculture that is managed and operated by large agribusiness corporations. These companies own or control large tracts of land and use advanced technologies to produce food at scale. It can encompass commercial farming but is typically done under a corporate structure.',
      processes: [
        'Vertical Integration: Corporate farms control multiple stages of production, from cultivation to distribution.',
        'Precision Farming: Use of GPS, drones, and IoT devices for real-time monitoring.',
        'Data-Driven Decisions: Analytics software for crop and soil management.',
        'Corporate Governance: Business-like governance focusing on profitability and scalability.',
      ],
      services: [
        'Agri-Tech Solutions: AI-based crop monitoring, robotics for harvesting.',
        'Sustainability Initiatives: Renewable energy usage, water conservation.',
        'Research & Development (R&D): Investments to improve crop varieties and resistance.',
        'Global Market Penetration: Resources for large-scale international trade.',
      ],
    },
    naturalFarming: {
      title: 'Natural Farming',
      overview:
        'Natural farming is a sustainable, eco-friendly approach to agriculture that emphasizes the use of natural inputs and processes. It focuses on building soil health, biodiversity, and minimizing external inputs such as chemical fertilizers and pesticides.',
      processes: [
        'Zero-Tillage Farming: Minimal soil disturbance.',
        'Use of Natural Inputs: Compost, manure, green manure, and bio-pesticides.',
        'Crop Diversity: Polyculture for maintaining soil fertility.',
        'Water Conservation: Efficient water usage practices such as mulching and rainwater harvesting.',
        'Pest Management: Biological pest control methods.',
      ],
      services: [
        'Eco-Certification & Organic Labels: Market products at premium prices.',
        'Training & Education: Workshops on sustainable practices.',
        'Local Market Support: Community-supported agriculture schemes.',
        'Soil Health Improvement: Consulting on natural soil enrichment methods.',
      ],
    },
    farmerDrivenFarming: {
      title: 'Farmer-Driven Farming',
      overview:
        'Farmer-driven farming refers to traditional or small-scale agriculture where farmers control the planning, production, and sale of their crops or livestock. It is often community-oriented and self-sustaining, prioritizing local markets over global trade.',
      processes: [
        'Family-Run Operations: Focus on crop diversity and local ecosystems.',
        'Mixed Farming: Combination of crop cultivation and animal husbandry.',
        'Resource Maximization: Minimal external inputs using local seeds and self-made composts.',
        'Crop Rotation & Fallow Fields: Natural soil fertility maintenance.',
      ],
      services: [
        'Community Support & Cooperatives: Shared machinery and collective marketing strategies.',
        'Local Market Sales: Direct sales to consumers or through farm-to-table initiatives.',
        'Extension Services: Access to improved seed varieties and sustainable techniques.',
        'Microfinance & Subsidies: Small-scale loans, grants, and government subsidies.',
      ],
    },
  };

  return (
    <>
      <h1 className="section-title">OUR PROCESS & SERVICES</h1>
      <div className="main-container">
        {Object.entries(content).map(([key, value]) => (
          <div key={key} className="card-main-OPS">
            <h2>{value.title}</h2>
            <p className='Overview-class'><strong>Overview:</strong> {value.overview}</p>

            {/* Processes Dropdown */}
            <div className="dropdown">
              <div
                className="dropdown-title_OPS"
                onClick={() => toggleContent(key, 'showProcesses')}
              >
                Processes {showContent[key].showProcesses ? '▲' : '▼'}
              </div>
              {showContent[key].showProcesses && (
                <ul className="dropdown-content_OPS">
                  {value.processes.map((process, index) => (
                    <li key={index}>{process}</li>
                  ))}
                </ul>
              )}
            </div>

            {/* Services Dropdown */}
            <div className="dropdown">
              <div
                className="dropdown-title_OPS"
                onClick={() => toggleContent(key, 'showServices')}
              >
                Services {showContent[key].showServices ? '▲' : '▼'}
              </div>
              {showContent[key].showServices && (
                <ul className="dropdown-content_OPS">
                  {value.services.map((service, index) => (
                    <li key={index}>{service}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default OurProcessAndServices;
