import React, { useEffect, useRef } from 'react';
import './Projects.css';

const Projects = () => {
  const projectRefs = useRef([]);

  const addToRefs = (el) => {
    if (el && !projectRefs.current.includes(el)) {
      projectRefs.current.push(el);
    }
  };

  useEffect(() => {
    // Removed IntersectionObserver logic as animations are no longer required.
  }, []);

  return (
    <section className="projects-section">
      <div className="projects-container" ref={addToRefs}>
        <img 
          src='https://res.cloudinary.com/drevfgyks/image/upload/v1729943297/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Screenshot_2024-10-26_171746_mqzqut.png' 
          className='project-image' 
          alt="Project Overview" 
        />
        <div className="project-content">
          <h2>Farming Methods</h2>
          
          {/* Commercial Farming */}
          <div className="project-item">
            <img 
              src="https://res.cloudinary.com/drevfgyks/image/upload/v1728176826/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/african-american-woman-inspecting-plants-doing-quality-control-hydroponic-enviroment-looking-green-leaves-greenhouse-agricultural-worker-cultivating-organic-lettuce-checking-pests_wogsau.jpg" 
              alt="Commercial Farming" 
              className="item-image"
            />
            <h3>1. Commercial Farming</h3>
            <p>
              Commercial farming is agriculture on a large scale with the primary goal of producing crops and livestock for sale in the market. It's profit-driven and often involves the use of advanced technology.
            </p>
            <ul>
              <li><strong>Intensive farming:</strong> High-input farming with modern techniques.</li>
              <li><strong>Mechanization:</strong> Use of machinery for plowing, sowing, and harvesting.</li>
              <li><strong>Monoculture:</strong> Focus on one or two cash crops.</li>
              <li><strong>Use of chemicals:</strong> Fertilizers, pesticides, and herbicides are common.</li>
              <li><strong>Irrigation systems:</strong> Large-scale irrigation is often employed.</li>
            </ul>
          </div>

          {/* Corporate Farming */}
          <div className="project-item">
            <img 
              src="https://res.cloudinary.com/drevfgyks/image/upload/v1723378843/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/woman-man-laboratory-robes-examine-carefully-plants-greenhouse_bnwsre.jpg" 
              alt="Corporate Farming" 
              className="item-image"
            />
            <h3>2. Corporate Farming</h3>
            <p>
              Corporate farming refers to large agribusinesses involved in the mass production of food and agricultural products. These are typically owned and operated by corporations rather than individual farmers.
            </p>
            <ul>
              <li><strong>Ownership:</strong> Corporations, often multinational, own and manage farms.</li>
              <li><strong>Vertical integration:</strong> Control the entire supply chain.</li>
              <li><strong>Economies of scale:</strong> Large-scale production to lower per-unit costs.</li>
              <li><strong>Genetic modification:</strong> Use of GM crops to increase yield.</li>
              <li><strong>Global markets:</strong> Aimed at export markets or large retail chains.</li>
            </ul>
          </div>

          {/* Natural Farming */}
          <div className="project-item">
            <img 
              src="https://res.cloudinary.com/drevfgyks/image/upload/v1725213500/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Screenshot_2024-09-01_215407_jbi0ip.png" 
              alt="Natural Farming" 
              className="item-image"
            />
            <h3>3. Natural Farming</h3>
            <p>
              Natural farming emphasizes ecological balance and sustainability, avoiding synthetic chemicals and relying on natural cycles and processes.
            </p>
            <ul>
              <li><strong>No chemicals:</strong> Avoidance of chemical fertilizers and pesticides.</li>
              <li><strong>Biodiversity:</strong> Encouraging a mix of crops and livestock.</li>
              <li><strong>No-till farming:</strong> Reducing soil disturbance.</li>
              <li><strong>Composting and green manure:</strong> Natural methods for soil health.</li>
              <li><strong>Water conservation:</strong> Sustainable water use.</li>
            </ul>
          </div>

          {/* Traditional Farming */}
          <div className="project-item">
            <img 
              src="https://res.cloudinary.com/drevfgyks/image/upload/v1728177163/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/lifestyle-ecofriendly-person_gots5b.jpg" 
              alt="Traditional Farming" 
              className="item-image"
            />
            <h3>4. Traditional (Farmer's) Farming</h3>
            <p>
              Traditional farming refers to small-scale practices passed down through generations, often family-owned and using a combination of modern and age-old methods.
            </p>
            <ul>
              <li><strong>Scale:</strong> Smaller farms, usually family-run.</li>
              <li><strong>Crop diversity:</strong> Ensures food security and resilience.</li>
              <li><strong>Mixed farming:</strong> Integration of crops and livestock.</li>
              <li><strong>Sustainable methods:</strong> Crop rotation and natural pest control.</li>
              <li><strong>Local markets:</strong> Products are sold locally or consumed by the family.</li>
            </ul>
          </div>
        </div>
      </div>
      <p className="EFE">Emerging Farmers Empowerment</p>
    </section>
  );
};

export default Projects;
