import React from 'react';
import './AgriBusinessServices.css';
import {useEffect} from 'react';

const AgriBusinessServices = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <section className="agri-business-services">
            <div className="container">
                <div className='titleOfBusinessSer'>
                    <h1 className='AGRI-BUSINESS'>AGRI-BUSINESS SERVICES</h1>
                </div>                
                <div className="services-content-agri-business">
                    <div className="service-box-agri-business">
                        <h1 style={{ color: 'black'}}>Business Profile</h1>
                        <p style={{ color: 'black'}}>Cultivating a Greener Future with Sustainable and Organic Farming</p>
                        <p className='Profile-para'>
                        ANT is a leading provider of commercial and organic farming services, 
                        committed to revolutionizing the agricultural landscape through innovation, 
                        sustainability, and eco-friendly practices. With a deep-rooted passion for the 
                        land, we integrate cutting-edge technology with time-honored farming traditions to 
                        produce high-quality crops that nourish both people and the planet.
                        </p>
                        <p><span style={{ color: 'black' }}>Our Mission:</span>To grow and deliver top-quality agricultural products using sustainable, organic, and environmentally responsible farming 
                        practices that support healthy living and a thriving ecosystem.</p>
                        <p><span style={{ color: 'black' }}>Our Vision:</span>To become a global leader in commercial and organic farming by driving sustainable agricultural practices and contributing 
                        to food security while maintaining a healthy and vibrant environment.</p>
                        <p ><span style={{ color: 'black' }}>Our Farms:</span>We operate several commercial and organic farms spread across 
                        diverse regions, each tailored to the unique climate and soil conditions to maximize productivity and sustainability. 
                        Visit our farms for a first-hand experience of our sustainable and organic farming processes.</p>
                        <p><span style={{ color: 'black' }}>Partnership Opportunities:</span>We welcome collaboration with retailers, distributors, food manufacturers, 
                        and eco-conscious businesses looking to source high-quality, sustainable, and organic agricultural products. Partner with us and contribute to 
                        a healthier, greener future.</p>
                    </div>
                    <div>
                        <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1724858572/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/6909_m58ryi.jpg' 
                        alt='image' className='business-img'/>
                    </div>
                </div>
            </div>
            <p className="EFE">Emerging Farmers Empowerment</p>
        </section>
    );
};

export default AgriBusinessServices;
