import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faHandsHelping, faTractor, faBuilding, faHeadset } from '@fortawesome/free-solid-svg-icons';
import './services.css';

const Services = () => {

    const containerRefs = useRef([]);
    containerRefs.current = [];

    const addToRefs = (el) => {
        if (el && !containerRefs.current.includes(el)) {
            containerRefs.current.push(el);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, { threshold: 0.1 });

        containerRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => {
            containerRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <div className='service-main-container'>
            <div className='our-services-banner'>
            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1725027840/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Screenshot_2024-08-30_195312_tdtgr3.png'
            className='services-banner'/>
            </div>
            <section className="services" ref={addToRefs} data-animation="slide-in-left">
                <div className="service-card">
                    <FontAwesomeIcon icon={faHandsHelping} className="service-icon" />
                    <h3>Commercial Farming</h3>
                    <p>Crop Production,Livestock Farming, Technology Integration, Supply Chain Management</p>
                </div>
                <div className="service-card">
                    <FontAwesomeIcon icon={faSeedling} className="service-icon" />
                    <h3>Organic Farming</h3>
                    <p>Certified Organic Produce, Sustainable Farming Practices, Farm-to-Table Partnerships, Regenerative Agriculture</p>
                </div>
                <div className="service-card">
                    <FontAwesomeIcon icon={faBuilding} className="service-icon" />
                    <h3>Farm Development Services</h3>
                    <p>Land Assessment and Planning, Farm Design and Construction, Farm Automation and Technology Integration</p>
                </div>
                <div className="service-card">
                    <FontAwesomeIcon icon={faHeadset} className="service-icon" />
                    <h3>Consulting and Training</h3>
                    <p>Farm Management Consulting, Organic Farming Workshops, Sustainability Audits</p>
                </div>
            </section>
            <section className="projects-section2">
    <div className="products-container"> 
        <div ref={addToRefs} className="product" data-animation="slide-right">
            <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725018118/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/business-agreement-shaking-hands-melon-plantation_brljhx.jpg" 
            className="product-image margin-img-one" alt="Wood Bearing crops" />
            <div className='product-content margin-one'>
                <h2>Commercial Farming</h2>
                <p className='product-des'>
                    At Amaravathi Naturo Tech, we specialize in large-scale commercial farming operations that meet the demands of global markets. 
                    Our experienced team utilizes advanced farming techniques, modern equipment, and best practices to ensure high yields and efficient production.
                </p>
                <ul className='product-des'>
                    <li><strong>Crop Production:</strong> Cultivating a wide range of grains, fruits, vegetables, and legumes to meet the demands of retailers and food manufacturers.</li>
                    <li><strong>Livestock Farming:</strong> Offering premium livestock products including dairy, poultry, and meat through ethical and sustainable practices.</li>
                    <li><strong>Technology Integration:</strong> Incorporating precision farming, GPS tracking, drone monitoring, and AI-powered data analysis to optimize crop yields and resource management.</li>
                    <li><strong>Supply Chain Management:</strong> Managing the entire process from farm to market, ensuring the freshness and quality of our products.</li>
                </ul>
            </div>
        </div>

        <div ref={addToRefs} className="product" data-animation="slide-left">
            <div className='product-content margin-two'>
                <h2>Organic Farming</h2>
                <p className='product-des'>
                    Our organic farming division focuses on growing healthy, chemical-free produce through sustainable farming methods. We prioritize the long-term health of the soil, biodiversity, and the local ecosystem.
                </p>
                <ul className='product-des'>
                    <li><strong>Certified Organic Produce:</strong> We offer a wide variety of fruits, vegetables, and grains that are 100% organic and grown without synthetic pesticides, herbicides, or fertilizers.</li>
                    <li><strong>Sustainable Farming Practices:</strong> Our farming approach preserves natural resources, enhances soil fertility, and promotes biodiversity.</li>
                    <li><strong>Farm-to-Table Partnerships:</strong> Collaborating with local markets, restaurants, and eco-conscious retailers to deliver fresh, organic produce directly from our farms to your tables.</li>
                    <li><strong>Regenerative Agriculture:</strong> Beyond organic, we implement regenerative practices that restore soil health, sequester carbon, and enhance water retention to combat climate change.</li>
                </ul>
            </div>
            <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725213502/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/Screenshot_2024-09-01_215437_o6hx7m.png"
            className="product-image margin-img-two" alt="Landscaping" />
        </div>
    </div>

    <div className="products-container"> 
        <div ref={addToRefs} className="product" data-animation="slide-right">
            <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1725001353/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/young-farmer-taking-care-his-business_ygqchv.jpg" 
            className="product-image margin-img-one" alt="Consulting and Training" />
            <div className='product-content margin-one'>
                <h2>Consulting and Training</h2>
                <p className='product-des'>
                    We offer expert consulting services and training programs for aspiring farmers and established agricultural businesses, helping them transition to more sustainable and organic farming practices.
                </p>
                <ul className='product-des'>
                    <li><strong>Farm Management Consulting:</strong> Tailored advice on optimizing commercial farm operations for better efficiency and profitability.</li>
                    <li><strong>Organic Farming Workshops:</strong> Hands-on training sessions covering soil health, organic pest control, and sustainable growing techniques.</li>
                    <li><strong>Sustainability Audits:</strong> Comprehensive evaluations of current farming practices with recommendations for eco-friendly improvements.</li>
                </ul>
            </div>
        </div>

        <div ref={addToRefs} className="product" data-animation="slide-left">
            <div className='product-content margin-two'>
                <h2>Farm Development Services</h2>
                <p className='product-des'>
                    For investors and entrepreneurs, Amaravathi Naturo Tech provides complete farm development solutions, from land acquisition and site planning to full-scale farm establishment.
                </p>
                <ul className='product-des'>
                    <li><strong>Land Assessment and Planning:</strong> Evaluating soil health, climate, and other factors to determine the best crops or livestock for your land.</li>
                    <li><strong>Farm Design and Construction:</strong> We build modern, efficient farm systems designed for high productivity and environmental sustainability.</li>
                    <li><strong>Farm Automation and Technology Integration:</strong> Implementing smart farming technologies, including irrigation systems, sensor networks, and real-time monitoring to boost efficiency.</li>
                </ul>
            </div>
            <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1723378843/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/woman-man-laboratory-robes-examine-carefully-plants-greenhouse_bnwsre.jpg"
            className="product-image margin-img-two" alt="Farm Development Services" />
        </div>
    </div>
    <p className="EFE">Emerging Farmers Empowerment</p>
</section>
          
        </div>
    );
};

export default Services;
