import React from "react";
import "./index.css";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import HomeProducts from "../HomeProducts/HomeProducts";
import OurProcessAndServices from "../OurProcessAndServices/OurProcessAndServices";
import BenefitsOffered from "../Benifits/benifits";
import SuccessJourney from "../SuccessJourney/SuccessJourney";
import BusinessProfile from "../BusinessProfile/BusinessProfile";

const benefits = [
  { title: 'Benifits_1', description: 'Description for Benifits_1', headingClass: 'benefit-heading-1' },
  { title: 'Benifits_2', description: 'Description for Benifits_2', headingClass: 'benefit-heading-2' },
  { title: 'Benifits_1', description: 'Description for Benifits_1', headingClass: 'benefit-heading-1' },
    // Add more items as needed
];

const Hero = () => {
  return (
    <>
      <CarouselComponent/>   
    <div className="container">      
      {/* <div className="sub-container">
        <div className="header">
          <h1>
            Innovating for a <span className="highlight">Sustainable Future</span>
          </h1>
          <p>
            We at SET (SREEKARA EnviroTech), customize and design equipment & solutions
            according to the customer application and requirement. Our highly qualified
            design and engineering team are having wide exposure towards technology and
            provide the best solution. We provide the most economical and reliable
            solution within the committed time frame.
          </p>
          <button className="contact-btn">
            Contact Us <span className="arrow">➔</span>
          </button>
        </div>        
        <div className="image-container">
          <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1722964757/AMARAVATHI%20NATURO%20TECH%20PVT%20LTD/ecology-concept-paper-style_kddxbu.png" alt="Sustainable Future" />
        </div>        
      </div> */}
        <HomeProducts/>
        <OurProcessAndServices/>
        <BenefitsOffered benefits={benefits} />        
        <BusinessProfile/>
        <SuccessJourney/>
        <p className="EFE">Emerging Farmers Empowerment</p>
    </div>        
    </>
  );
};

export default Hero;